<template>
    <div class="avue-crud">
      <el-form :model="queryParams" ref="queryForm" :inline="true">
         <el-form-item :label="$t('currencySetNft.name1')" prop="tenantId" v-if='userStore().getIsPlatform'>
                <el-select v-model="queryParams.tenantId" :placeholder="$t('currencySetNft.name1')" :teleported='false'>
                    <el-option :label="item.name" :value="item.id" v-for='item in tenantArray' :key = 'item.id'/>
                </el-select>
        </el-form-item>
        <el-form-item :label="$t('deptFrom.text2')" prop="deptName">
          <el-input v-model="queryParams.deptName" :placeholder="$t('deptFrom.text2')" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary"   @click="getList">{{$t('button.search')}}</el-button>
          <el-button v-if="sys_menu_add"   type="primary"
                     @click="addOrUpdateHandle(false)"> {{$t('button.add')}} </el-button>
        </el-form-item>
      </el-form>

      <el-table border v-loading="loading" height='calc(100vh - 200px)'
        :data="deptList" row-key="id" default-expand-all :tree-props="{children: 'children'}">
        <el-table-column prop="name" :label="$t('deptFrom.text2')" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="weight" :label="$t('deptFrom.text3')" align="center"></el-table-column>
        <el-table-column prop="tenantName" :label='$t("currencySet.name1")' align="center"></el-table-column>
        <el-table-column prop="createTime" :label="$t('deptFrom.text4')" align="center">
            <template #default="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
        </el-table-column>
        <el-table-column :label="$t('deptFrom.text5')" align="center" class-name="small-padding fixed-width">
          <template #default="scope">
            <el-button size="small"   type='primary' text
                @click="addOrUpdateHandle(false,scope.row.id)" v-if="sys_dept_add">{{$t('button.add')}}
            </el-button>
            <el-button size="small"   type='primary' text
                @click="addOrUpdateHandle(true,scope.row.id)" v-if="sys_dept_edit">{{$t('button.update')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <table-form ref="addOrUpdate" @refreshDataList="getList"></table-form>
    </div>
</template>

<script setup>
    import { ref,reactive,getCurrentInstance } from 'vue'
    import Api_ from '@/api/index'
    import TableForm from './dept-form'
    const { $t } = getCurrentInstance().proxy;
    import { userStore } from '@/store/user';///userStore().getIsPlatform
    import apiList from '@/const/apiList'
    import { turnTimeFun } from '@/utils/util.js'

    const queryParams = reactive({deptName: "",tenantId:''})
    const loading = ref(false)
    const deptList = ref([])
    const tenantArray = ref([])
    //权限
    const sys_menu_add = ref(true)
    const sys_dept_add = ref(true)
    const sys_dept_edit = ref(true)
    //表单组件
    const addOrUpdate = ref(null)
    apiList.getTenantList((data)=>{ tenantArray.value = data })
    
    const getList = ()=>{
        loading.value = true
        Api_.deptList(queryParams).then(res=>{
            if(res.code === 0){
                loading.value = false
                deptList.value = res.data
            }
        })
    }
    getList()

    const addOrUpdateHandle = (isEdit, id)=>{
        addOrUpdate.value.init_(isEdit, id)
    }
</script>
<style lang="scss" scoped>
    :deep(.el-dialog__body) {
       padding-top: 0;
    }
</style>
